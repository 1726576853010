






























































import { Component, Prop, Vue } from "vue-property-decorator";
import { AuditSurvey as IAuditSurvey } from "@/models/entities/audit-survey.interface";

const AnswerText = () =>
  import(/* webpackChunkName: "AnswerText" */ "./SurveyAnswers/AnswerText.vue");
const AnswerTextList = () =>
  import(
    /* webpackChunkName: "AnswerTextList" */ "./SurveyAnswers/AnswerTextList.vue"
  );
const AnswerYesNoNP = () =>
  import(
    /* webpackChunkName: "AnswerYesNoNP" */ "./SurveyAnswers/AnswerYesNoNP.vue"
  );

const AnswerPhotoList = () =>
  import(
    /* webpackChunkName: "AnswerYesNoNP" */ "./SurveyAnswers/AnswerPhotoList.vue"
  );

@Component({
  components: {
    "answer-text": AnswerText,
    "answer-text-list": AnswerTextList,
    "answer-yes-no-np": AnswerYesNoNP,
    "answer-photo-list": AnswerPhotoList,
  },
})
export default class AuditSurveyGroup extends Vue {
  @Prop({ required: false, default: "", type: String })
  private group: string;
  @Prop({ required: false, default: () => [], type: Array })
  private dataByGroup: IAuditSurvey[];

  private get questionNames(): string[] {
    return [
      ...new Set(
        this.dataByGroup.map(
          (el) => el.auditEquipmentName || `#${el.auditEquipmentGroup}`
        )
      ),
    ];
  }

  private get dataByName(): { [id: string]: IAuditSurvey[] } {
    return this.dataByGroup.reduce((res, value) => {
      const prop = value.auditEquipmentName || `#${value.auditEquipmentGroup}`;
      (res[prop] = res[prop] || []).push(value);
      return res;
    }, {});
  }
}
